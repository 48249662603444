import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { mapDefaultTasq } from '@/utils/tasqs';
import workflowApolloClient from '@/lib/appsync/workflow';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import getListEnabledWells from '@/graphql/workflow/queries/getListEnabledWells.graphql';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'assetsModule',
  store,
})
class AssetsModule extends VuexModule {
  enabledWells: any = [];

  @Mutation
  setEnabledWells(data): void {
    this.enabledWells = data;
  }

  @Action
  async getEnabledWells(): Promise<void> {
    const {
      data: {
        list_enabled_wells: {
          enabled_wells: wells,
        },
      },
    } = await workflowApolloClient.query({
      query: getListEnabledWells,
      variables: {
        operator: getConfigEnv('OPERATOR_LOWERCASED'),
      },
    });

    const items = wells.map((i) => {
      const parsed = JSON.parse(i);
      return {
        wellName: parsed.NodeID,
        text: parsed.NodeID,
        value: parsed.NodeID,
        id: parsed.NodeID,
        engineerType: 'Producing',
        predictionType: 'Producing',
        assignee: {},
        route: parsed.Route,
        routeId: parsed.RouteID,
      };
    });

    this.setEnabledWells(items);
  }
}

export default getModule(AssetsModule);
